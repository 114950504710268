export class Constants {

    public static SuccessTitle: any = "Success";
    public static ErrorTitle: any = "Error";
    public static WarningTitle: any = "Warning";

    public static SuccessType: any = "success";
    public static ErrorType: any = "error";
    public static WarningType: any = "warning";


    public static RecordAddTitle: any = "Record Added Successfully";
    public static RecordUpdateTitle: any = "Record Updated Successfully";
    public static RecordDeleteTitle: any = "Record Deleted Successfully";
    public static RecordLimit: any = 10 ;
    public static USER_ID:any = "";
    public static MASTER_SETTING_USER_ID:any = 1;
    

    //FOR LOCAL
    //public static BASE_URL:any ='http://127.0.0.1:8000/api';
   //public static CONSUMER_API_URL:any ='http://127.0.0.1:8000/api';

   public static BASE_URL:any ='https://provider.odbus.co.in/api';
   public static CONSUMER_API_URL:any ='https://consumer.odbus.co.in/api';
   public static CONSUMER_BASE_URL:any ='https://www.odbus.in/';


   // public static BASE_URL:any ='https://testingadminapi.odbus.co.in/api';
   // public static CONSUMER_API_URL:any ='https://testing.odbus.co.in/api';
   // public static CONSUMER_BASE_URL:any ='https://odtesting.odbus.co.in/';

    
}