<div class="text-center btn btn-info form-control admin-type"><b style="color: rgb(0, 255, 42);">Hi </b> <small>{{adminType}}</small></div>
<perfect-scrollbar *ngIf="this.flatConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-flat-able" ngClass="flat-able-scroll" [style.max-width]="'300px'" [usePSClass]="'flat-able'" [disabled]="null">
  <div class="navbar-content ">
  
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    
  </div>

  <div *ngIf="roleId!=1 && roleId!=2" class="border-box" >
    <img src="/assets/images/calling_woman_vector.png" style="width: 100%; margin-left: -6px;" >
    <div class="col-12 text-center ft-18 lead"><a href="mailto:support@odbus.in"><i class="fa fa-envelope"></i> agent@odbus.in</a></div>
    <div class="col-12 text-center ft-18 lead"><a href="tel:095839 18888"><i class="fa fa-phone"></i> 095839 18888</a></div>
  </div>
  
  
</perfect-scrollbar>

<div *ngIf="this.flatConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal" id="layout-sidenav">
  <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
  <div  class="sidenav-horizontal-wrapper"><!-- add 14-4 viral -->
    <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner" (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>
